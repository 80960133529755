export const columns = [
    {
    name: 'key',
    field: 'key',
    label: 'Key',
    align: 'center',
  },
  {
    name: 'action',
    field: 'action',
    label: '',
    align: 'center',
    headerStyle: 'width: 100px',
  },
];